import axios from 'axios';

export default {
    namespaced: true,
    state: {
        user: null
    },
    getters: {
        user: (state) => {
            return state.user;
        }
    },
    mutations: {
        CLEAR_USER(state) {
            state.user = null;
        },
        UPDATE_USER(state, { user }) {
            state.user = user;
        }
    },
    actions: {
        async fetchUser({ commit }) {
            // catch error and logout user
            const { data } = await axios.get(route('api.admin.get-one'));
            commit('UPDATE_USER', { user: data });
        },
        logout({ commit }) {
            commit('CLEAR_USER');
        }
    }
};
