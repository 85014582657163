import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import AuthRoutes from '@/router/authRoutes';
import MainRoutes from '@/router/mainRoutes';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/:pathMatch(.*)*',
            component: () => import('@/components/General/PageNotFound.vue')
        },
        AuthRoutes,
        MainRoutes
    ]
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.title) {
        document.title = `E&C - ${to.meta.title}`;
    }
    if (to.meta.middleware === 'guest') {
        next();
    } else {
        if (store.state.auth.user === null) {
            let query = to.path !== '/user' ? { redirect: to.fullPath } : {};
            next({ name: 'auth.login', query });
        } else {
            try {
                await store.dispatch('auth/fetchUser');
                next();
            } catch (error) {
                next({ name: 'auth.login' });
            }
        }
    }
});

export default router;
