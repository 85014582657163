const MainRoutes = {
    path: '/',
    component: () => import('@/components/Layouts/Layout.vue'),
    meta: {},
    children: [
        {
            path: '/',
            component: () => import('@/components/Layouts/Default.vue'),
            children: [
                {
                    name: 'dashboard',
                    path: '/dashboard',
                    component: () => import('@/components/Dashboards/Dashboard.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Dashboard'
                    }
                },
                {
                    name: 'broadcast',
                    path: '/broadcast',
                    component: () => import('@/components/Broadcasts/BroadcastList.vue'),
                    meta: {
                        middleware: 'auth',
                        title: 'Broadcast'
                    }
                }
            ]
        }
    ]
};

export default MainRoutes;
