import '@/bootstrap';
import vuetify from './plugins/vuetify';
import Router from '@/router';
import store from '@/store';
import mitt from 'mitt';
import { createApp } from 'vue/dist/vue.esm-bundler';
import '@sass/app.scss';
import VueScrollTo from 'vue-scrollto';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import * as Sentry from '@sentry/vue';

// vue-block-tree
import VueBlocksTree from 'vue3-blocks-tree';
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css';

const app = createApp({});

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(Router)
        }),
        new Sentry.Replay()
    ],

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
});

app.config.globalProperties.emitter = mitt();
app.use(Router);
app.use(store);
app.use(PerfectScrollbar);
app.use(VueBlocksTree, { treeName: 'blocks-tree' });
app.use(vuetify).mount('#app');
app.use(VueScrollTo, {
    duration: 1000,
    easing: 'ease'
});
