import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';
import { en } from 'vuetify/locale';
import { BLUE_THEME } from '@/theme/LightTheme';

export default createVuetify({
    locale: {
        locale: 'en',
        fallback: 'en',
        messages: { en }
    },
    theme: {
        defaultTheme: 'BLUE_THEME',
        themes: {
            BLUE_THEME
        }
    },
    defaults: {
        VCard: {
            rounded: 'md'
        },
        VTextField: {
            variant: 'outlined',
            density: 'comfortable',
            color: 'primary'
        },
        VTextarea: {
            variant: 'outlined',
            density: 'comfortable',
            color: 'primary'
        },
        VSelect: {
            variant: 'outlined',
            density: 'comfortable',
            color: 'primary'
        },
        VListItem: {
            minHeight: '45px'
        },
        VTooltip: {
            location: 'top'
        }
    }
});
