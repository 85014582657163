import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from '@/store/auth';
import custom from '@/store/custom';

const store = createStore({
    plugins: [createPersistedState()],
    modules: {
        auth,
        custom
    }
});

export default store;
