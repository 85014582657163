export default {
    namespaced: true,
    state: {
        sidebarDrawer: null
    },
    getters: {
        sidebarDrawer: (state) => {
            return state.sidebarDrawer;
        }
    },
    mutations: {
        SET_SIDEBAR_DRAWER(state, payload) {
            state.sidebarDrawer = payload;
        }
    },
    actions: {
        setSideBarDrawer({ commit }, payload) {
            commit('SET_SIDEBAR_DRAWER', payload);
        }
    }
};
