const BLUE_THEME = {
    name: 'BLUE_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef'
    },
    colors: {
        primary: '#186dde',
        secondary: '#0acc95',
        info: '#7460ee',
        success: '#13DEB9',
        accent: '#fc4b6c',
        warning: '#fec90f',
        error: '#ef5350',
        lightprimary: '#f5fcfd',
        lightsecondary: '#E8F7FF',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#e0e3e6',
        hoverColor: '#f6f9fc',
        surface: '#fff',
        'on-surface-variant': '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4',
        muted: '#1d2228',
        borderline: '#1d1d1d'
    }
};

const AQUA_THEME = {
    name: 'AQUA_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef'
    },
    colors: {
        primary: '#0074BA',
        secondary: '#47D7BC',
        info: '#7460ee',
        success: '#13DEB9',
        accent: '#fc4b6c',
        warning: '#fec90f',
        error: '#ef5350',
        lightprimary: '#EFF9FF',
        lightsecondary: '#EDFBF7',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#f4f6f9',
        hoverColor: '#f6f9fc',
        surface: '#fff',
        'on-surface-variant': '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4',
        muted: '#1d2228',
        borderline: '#1d1d1d'
    }
};

const PURPLE_THEME = {
    name: 'PURPLE_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef'
    },
    colors: {
        primary: '#763EBD',
        secondary: '#95CFD5',
        info: '#7460ee',
        success: '#13DEB9',
        accent: '#fc4b6c',
        warning: '#fec90f',
        error: '#ef5350',
        lightprimary: '#F2ECF9',
        lightsecondary: '#EDF8FA',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#f4f6f9',
        hoverColor: '#f6f9fc',
        surface: '#fff',
        'on-surface-variant': '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4',
        muted: '#1d2228',
        borderline: '#1d1d1d'
    }
};

const GREEN_THEME = {
    name: 'GREEN_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef'
    },
    colors: {
        primary: '#0A7EA4',
        secondary: '#CCDA4E',
        info: '#7460ee',
        success: '#13DEB9',
        accent: '#fc4b6c',
        warning: '#fec90f',
        error: '#ef5350',
        lightprimary: '#F4F9FB',
        lightsecondary: '#FAFBEF',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#f4f6f9',
        hoverColor: '#f6f9fc',
        surface: '#fff',
        'on-surface-variant': '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4',
        muted: '#1d2228',
        borderline: '#1d1d1d'
    }
};

const CYAN_THEME = {
    name: 'CYAN_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef'
    },
    colors: {
        primary: '#01C0C8',
        secondary: '#FB9678',
        info: '#7460ee',
        success: '#00e676',
        accent: '#fc4b6c',
        warning: '#fec90f',
        error: '#ef5350',
        lightprimary: '#EBF9FA',
        lightsecondary: '#FFF5F2',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#f4f6f9',
        hoverColor: '#f6f9fc',
        surface: '#fff',
        'on-surface-variant': '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4',
        muted: '#1d2228',
        borderline: '#1d1d1d'
    }
};

const ORANGE_THEME = {
    name: 'ORANGE_THEME',
    dark: false,
    variables: {
        'border-color': '#e5eaef'
    },
    colors: {
        primary: '#FA896B',
        secondary: '#0074BA',
        info: '#7460ee',
        accent: '#fc4b6c',
        warning: '#fec90f',
        error: '#ef5350',
        lightprimary: '#FBF2EF',
        lightsecondary: '#EFF9FF',
        lightsuccess: '#E6FFFA',
        lighterror: '#FDEDE8',
        lightwarning: '#FEF5E5',
        textPrimary: '#2A3547',
        textSecondary: '#2A3547',
        borderColor: '#e5eaef',
        inputBorder: '#DFE5EF',
        containerBg: '#ffffff',
        background: '#f4f6f9',
        hoverColor: '#f6f9fc',
        surface: '#fff',
        'on-surface-variant': '#fff',
        grey100: '#F2F6FA',
        grey200: '#EAEFF4',
        muted: '#1d2228',
        borderline: '#1d1d1d'
    }
};

export { BLUE_THEME, AQUA_THEME, ORANGE_THEME, PURPLE_THEME, GREEN_THEME, CYAN_THEME };
