const AuthRoutes = {
    path: '/',
    component: () => import('@/components/Layouts/Layout.vue'),
    meta: {},
    children: [
        {
            name: 'auth.login',
            path: '/',
            component: () => import('@/components/Auth/Login.vue'),
            meta: {
                middleware: 'guest',
                title: 'Login'
            }
        }
    ]
};

export default AuthRoutes;
